import { FC } from 'react';

import Link from 'next/link';

import { useMediaQuery } from '@mui/material';

import { usePagePathParams } from '@hooks_common/usePagePathParams';

import { MediaQuery } from '@constants_common';
import { NAVIGATION_COMPANIES, NAVIGATION_MEDIA } from '@constants_iqfu';

import styles from './Footer.module.scss';
import ExternalLink from '../external_link/ExternalLink';

const Footer: FC = () => {
  const isMobile = useMediaQuery(MediaQuery.lg);

  const CompaniesNavigationsFlattenedArray = NAVIGATION_COMPANIES.flat(); // 配列を平坦化
  const CompaniesNavigationsFirstHalf = CompaniesNavigationsFlattenedArray.slice(0, 5);
  const CompaniesNavigationsLastHalf = CompaniesNavigationsFlattenedArray.slice(-5);

  const pathParam = usePagePathParams();
  const mediaLinkQueryParam = `?utm_source=iqfu&utm_medium=referral&utm_campaign=footer&utm_content=${pathParam}`;

  return (
    <footer className={styles.container}>
      <div className={styles.top_column}>
        <div className={styles.logo_wrapper}>
          <Link href="/" prefetch={false}>
            <img src="/images/iqfu/logo.svg" alt="イクラ不動産" />
          </Link>
        </div>
        <div className={styles.nav}>
          <div className={styles.nav_top}>不動産会社を探す</div>
          <div className={styles.footer_nav_list_stacks_desktop}>
            <ul className={styles.nav_list}>
              {CompaniesNavigationsFirstHalf.map((item, i) => (
                <li className={styles.nav_item} key={i}>
                  <Link href={item.link} prefetch={false}>
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className={styles.nav_list}>
              {CompaniesNavigationsLastHalf.map((item, i) => (
                <li className={styles.nav_item} key={i}>
                  <Link href={item.link} prefetch={false}>
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.nav}>
          <div className={styles.nav_top}>物件の価格を知る</div>
          <ul className={`${styles.nav_list} ${styles.nav_list_horizontal}`}>
            <li className={styles.nav_item}>
              <Link prefetch={false} href="/landhouses">
                土地・戸建価格
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link prefetch={false} href="/mansions">
                マンション価格
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link prefetch={false} href="/simulator">
                価格シミュレーター
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.nav}>
          <div className={styles.nav_top}>売却相談チャット</div>
          <ul className={`${styles.nav_list} ${styles.nav_list_horizontal}`}>
            <li className={styles.nav_item}>
              <Link prefetch={false} href={`/signup`}>
                新規売却相談
              </Link>
            </li>
            <li className={styles.nav_item}>
              <Link prefetch={false} href={`/login`}>
                ログイン
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* .top_column */}
      <div className={styles.second_column}>
        <div className={styles.media_nav_head}>お家の売却を学ぶ</div>
        <div className={styles.media_nav_container}>
          <div className={styles.media_nav_wrapper}>
            <div className={styles.media_nav_top}>お家の売却 まずはここから</div>
            <ul className={styles.media_nav_list}>
              <li className={styles.media_nav_item}>
                <Link prefetch={false} href="/tips-to-choose-companies">
                  お家を高く売るための不動産屋選びのコツ
                </Link>
              </li>
              <li className={styles.media_nav_item}>
                <Link prefetch={false} href="/features-of-high-selling-houses-lands">
                  高く売れる一戸建て・土地の特徴&査定方法の基礎知識
                </Link>
              </li>
              <li className={styles.media_nav_item}>
                <Link prefetch={false} href="/features-of-high-selling-mansions">
                  高く売れるマンションの特徴&査定方法の基礎知識
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.media_nav_wrapper}>
            <div className={styles.media_nav_top}>お家の売却 こんなときどうするの？</div>
            <ul className={styles.media_nav_list}>
              {Object.entries(NAVIGATION_MEDIA.outi).map(([key, value]) => (
                <li key={key} className={styles.media_nav_item}>
                  <ExternalLink href={value.url + mediaLinkQueryParam} target={isMobile ? '_self' : '_blank'}>
                    {value.text}
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.madia_nav_wrapper}>
            <div className={styles.media_nav_top}>不動産売却基礎知識</div>
            <ul className={styles.media_nav_list}>
              {Object.entries(NAVIGATION_MEDIA.kiso).map(([key, value]) => (
                <li key={key} className={styles.media_nav_item}>
                  <ExternalLink href={value.url + mediaLinkQueryParam} target={isMobile ? '_self' : '_blank'}>
                    {value.text}
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* .second_column */}
      <div className={styles.bottom_link_list}>
        <div className={styles.bottom_link_stacks}>
          <ExternalLink
            href={`https://iqrafudosan.com/smpf/about-ieiqra/${mediaLinkQueryParam}`}
            target={isMobile ? '_self' : '_blank'}
          >
            <span className={styles.bottom_link_item}>イクラ不動産とは</span>
          </ExternalLink>
          <span className={styles.bottom_link_item_divider}></span>
          <Link prefetch={false} href="/privacy-policy" className={styles.bottom_link_item}>
            プライバシーポリシー
          </Link>
          <span className={styles.bottom_link_item_divider}></span>
          <Link prefetch={false} href="/terms-of-service" className={styles.bottom_link_item}>
            利用規約
          </Link>
        </div>
        <span className={`${styles.bottom_link_item_divider} ${styles.desktop_only}`}></span>
        <div className={styles.bottom_link_stacks}>
          <ExternalLink
            href={`https://iqrafudosan.com/channel/${mediaLinkQueryParam}`}
            target={isMobile ? '_self' : '_blank'}
          >
            <span className={styles.bottom_link_item}>不動産会社向け情報</span>
          </ExternalLink>
          <span className={styles.bottom_link_item_divider}></span>
          <ExternalLink href={`https://iqra.co.jp/${mediaLinkQueryParam}`} target={isMobile ? '_self' : '_blank'}>
            <span className={styles.bottom_link_item}>運営会社</span>
          </ExternalLink>
        </div>
      </div>
      <div className={styles.copyRight}>
        <small>&copy;Copyright2021 iQrafudosan.All Rights Reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
