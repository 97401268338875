import Head from 'next/head';
import Link from 'next/link';

import type { NextPage } from 'next';

import Footer from '@components_iqfu/public/footer/Footer';
import PublicPageContainer from '@components_iqfu/public/public_page_container/PublicPageContainer';

import styles from '@styles_iqfu/public/error_page.module.scss';

const error404page: NextPage = () => {
  return (
    <PublicPageContainer>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <Link href="/" prefetch={false}>
        <img src="/images/iqfu/logo.svg" className={styles.logo} alt="ロゴ" />
      </Link>
      <p className={styles.title}>404</p>
      <p className={styles.title_sub}>Not Found</p>
      <div className={styles.text_wrapper}>
        <img src="/images/iqfu/public/ikuko_search.svg" className={styles.ikuko} alt="イク子さん" />
        <p className={styles.error_text}>
          お探しのページが見つかりません。
          <br />
          一時的にアクセスできない状況にあるか、
          <br />
          移動もしくは削除された可能性があります。
        </p>
      </div>

      <div className={styles.top_link}>
        <Link href="/" prefetch={false}>
          イクラ不動産トップへ
        </Link>
      </div>

      <Footer />
    </PublicPageContainer>
  );
};

export default error404page;
